import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import Swal from "sweetalert2";
export default function Landing1() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;

  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  //////////////////////////////////////////////////

  const [open, setOpen] = useState(false);
  const [ludoking, setludoking] = useState(false);
  const [webludo, setwebludo] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setludoking(data.ludoking);
    setwebludo(data.classiclite);
    return setWebsiteSettings(data);
  };
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("m") == "s") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The transaction was successful!",
      });
    } else if (params.get("m") == "f") {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "The transaction failed. Please try again.",
      });
    }
  }, [location]);
  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (access_token) {
      await axios
        .get(baseUrl + `me`, { headers })
        .then((res) => {
          setUserAllData(res.data);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };
  useEffect(() => {
    role();
    fetchData();
  }, []);
  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
          <section
            style={{
              position: "fixed",
              bottom: "10px",
              zIndex: "999",
              margin: "10px",
              right: "5px",
            }}
          ></section>

          <section className="games-section p-3">
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
                {ludoking && (
                  <>
                    <picture className="gameCard-image">
                      <img
                        width="100%"
                        src={process.env.PUBLIC_URL + "/Images/home.webp"}
                        alt=""
                      />
                    </picture>
                  </>
                )}
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <center>
              <Link to="/login">
                <div
                  style={{
                    backgroundColor: "red",
                    width: "90%",
                    height: "35px",
                    padding: "5px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textDecoration: "none", color: "#fff" }}>
                    Play Now
                  </p>
                </div>
              </Link>
            </center>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/Header_profile.jpg"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "56px", height: "56px" }}
                />
              </picture>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
                className={!open ? "d-block" : "d-none"}
              >
                Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{
                    fontSize: "12px",
                    color: "rgb(103, 103, 103)",
                    textAlign: "center",
                  }}
                ></i>
              ) : (
                <i
                  style={{
                    fontSize: "12px",
                    color: "#000",
                    textAlign: "center",
                  }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <h5 style={{ color: "#000" }}>Our Business & Products</h5>
                  <p style={{ color: "#000" }}>
                    We are an HTML5 game-publishing company and our mission is
                    to make accessing games fast and easy by removing the
                    friction of app-installs. Loganludo is a skill-based real-money
                    gaming platform accessible only for our users in India. It
                    is accessible on https://www.loganludo.com On Loganludo, users
                    can compete for real cash in Tournaments and Battles. They
                    can encash their winnings via popular options such as Paytm
                    Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.
                  </p>
                </div>
              </div>
            </Collapse>
            <div className="footer-divider" />
            <div className="px-3 py-4"></div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
    </>
  );
}
